import React, { Component } from 'react'
import DesignRender from '../../DesignRender'
import DesignRenderPDF from '../../DesignRenderPDF'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactTooltip from 'react-tooltip'
import 'react-circular-progressbar/dist/styles.css';
import styles from './multi-submit.module.css'
import async from 'async'
import {
  buildRenderLayers,
  submitMultipleDesigns,
  postFile,
  processMultipleDesigns,
} from '../../../utils/design'
import {
  getUserInfo
} from '../../../utils/user'

class RenderQueue extends Component {
  constructor(props) {
    super(props)
    let project = {...this.props.project}
    let ableToSubmit = project.designs.length
    let canvasBlobs = []
    let message = ''
    let hasCustomMixed = false
    let customMixSelected = false

    if(ableToSubmit > 0){
      message = 'Rendering Design Files. Please Wait...'
      let isCustom = project.designs[0].config.hasCustomSurface
      project.designs.forEach((design, i) => {
        if(design.config.hasCustomSurface !== isCustom){
          hasCustomMixed = true
          customMixSelected = true
        }
      })
    }else{
      message = 'No designs available to submit. Return to the Project page and complete at least one design.'
    }

    this.state = {
      project: project,
      submitDisabled: true,
      canvasBlobs: canvasBlobs,
      imageRenders: [],
      ableToSubmit: ableToSubmit,
      rendered: 0,
      selected: ableToSubmit,
      renderedImages: 0,
      renderedPdfs: 0,
      designsSubmitted: 0,
      uploaded: 0,
      message: message,
      userAttributes: null,
      submitComplete: false,
      proceeding: false,
      hasCustomMixed: hasCustomMixed,
      customMixSelected: customMixSelected
    }
  }

  renderedImages = 0
  renderedPdfs = 0

  componentDidMount() {
    getUserInfo()
    .then(function(user){
      this.setState({
        userAttributes: user
      })
    }.bind(this))
  }

  checkCustomMixSelected = () => {
    let customMixSelected = false
    let selected = this.state.project.designs.filter((design)=>{
      return design.selected
    })
    let isCustom = selected[0].config.hasCustomSurface
    selected.forEach((design, i) => {
      if(design.config.hasCustomSurface !== isCustom){
        customMixSelected = true
      }
    });
    return customMixSelected
  }

  setErrorMessage = (message) => {
    this.setState({
      message: message
    })
  }

  updateDesignsSubmitted = () => {
    let designsSubmitted = this.state.designsSubmitted
    this.setState({
      designsSubmitted: designsSubmitted + 1
    })
  }

  submitAllReady = () => {
    this.setState({
      message: 'Submitting designs...',
      submitDisabled: true,
      proceeding: true
    })

    let designIds = []
    let readyDesigns = this.state.project.designs.filter(function(design, i){
      return (design.selected)
    })

    readyDesigns.forEach((design, i) => {
      designIds.push(design._id)
    })

    submitMultipleDesigns({designIds: designIds})
    .then(function(s3Urls){
      s3Urls.data.forEach((urls, i) => {
        readyDesigns[i].urls = urls
      })
      async.each(readyDesigns, function(design, callback){
        postFile(design.urls.pdfPost, design.pdfFileData, 'application/pdf')
        .then(function(response){
          postFile(design.urls.jpgPost, design.lsbdrsImage, 'image/jpeg')
          .then(function(response){
            this.updateDesignsSubmitted()
            callback()
          }.bind(this))
        }.bind(this))
      }.bind(this), function(err){
        if(err){
          this.setstate({
            message: err.message,
            submitComplete: true
          })
        }else{
          processMultipleDesigns({designIds: designIds})
          .then(function(){
            this.setState({
              message: 'All selected designs have been submitted.',
              submitComplete: true
            })
          }.bind(this))
        }
      }.bind(this))
    }.bind(this))

  }

  getCanvasBlob = (index, blob) => {
    this.props.project.designs[index].lsbdrsImage = blob
    let renderedCount = this.props.project.designs.filter(function(design, i){
      return design.lsbdrsImage
    }).length
    if(renderedCount === this.props.project.designs.length){
      this.setState({
        renderedImages: renderedCount
      })
    }
  }

  getCanvasImage = (index, image) => {
    this.props.project.designs[index].canvasImage = image
  }

  getPdfData = (index, data) => {
    this.props.project.designs[index].pdfFileData = data
    let submitDisabled = true
    let message = 'Rendering Design Files. Please Wait...'
    let renderedCount = this.props.project.designs.filter(function(design, i){
      return design.pdfFileData
    }).length
    if((renderedCount === this.state.ableToSubmit) && !this.state.customMixSelected){
      submitDisabled = false
      message = 'Ready to submit.'
    }
    let customMixSelected = this.checkCustomMixSelected()
    this.setState({
      customMixSelected: customMixSelected,
      renderedPdfs: renderedCount,
      rendered: renderedCount,
      submitDisabled: submitDisabled,
      message: message
    })
  }

  toggleSelect = (index, selected) => {
    this.props.project.designs[index].selected = selected
    let selectedCount = this.props.project.designs.filter((design, index)=>{
      return (design.selected)
    }).length
    let submitDisabled = true
    if(selectedCount > 0 && (this.state.ableToSubmit === this.state.rendered)){
      submitDisabled = false
    }
    if(this.checkCustomMixSelected()){
      submitDisabled = true
    }
    this.setState({
      selected: selectedCount,
      submitDisabled: submitDisabled
    })
  }

  selectStandardOrCustom = (custom) => {
    this.props.project.designs.forEach((design, i) => {
      if(design.config.hasCustomSurface === custom){
        design.selected = true
      }else{
        design.selected = false
      }
    })
    let selectedCount = this.props.project.designs.filter((design, index)=>{
      return (design.selected)
    }).length
    let submitDisabled = true
    if(selectedCount > 0 && (this.state.ableToSubmit === this.state.rendered)){
      submitDisabled = false
    }
    this.setState({
      selected: selectedCount,
      submitDisabled: submitDisabled
    })
  }

  render() {
    let designRenders = null
    let progressStyle = buildStyles({
      // Text size
      textSize: '16px',
      // How long animation takes to go from one percentage to another, in seconds
      pathTransitionDuration: 0.5,
      // Can specify path transition in more detail, or remove it entirely
      // pathTransition: 'none',
      // Colors
      pathColor: `#8DC63F`,
      textColor: '#808284',
      trailColor: '#d6d6d6',
      backgroundColor: '#3e98c7',
    })
    designRenders = this.props.project.designs.map((design, index) => {
      let renderLayers = buildRenderLayers(design.config)
      return(
        <div key={design._id} role="button" tabIndex={index} onKeydown={(e) => {this.toggleSelect(index, !design.selected)}} onClick={(e) => {this.toggleSelect(index, !design.selected)}} className={(design.selected ? styles['renderItemSelected'] : styles['renderItem'])}>
          {design.config.hasCustomSurface &&
            <i className="las la-exclamation-triangle" data-tip data-for="tooltipCustom" style={{position: 'absolute', top: '0px', right: '0px'}}></i>
          }
          {!design.canvasImage &&
            <DesignRender
              configuration={renderLayers}
              getCanvasBlob={(blob)=>{
                this.getCanvasBlob(index, blob)
              }}
              getCanvasImage={(image)=>{
                this.getCanvasImage(index, image)
              }}
              hideWatermark={this.props.hideWatermark}
              invisible={false}
              key={design._id}
              waitLoaderSize={40}
              id={'multi-submit-render-queue-'+design._id}
            />
          }
          {design.canvasImage &&
            <img src={design.canvasImage} width="144px" height="200px" alt="design" />
          }
          { design.canvasImage &&
            <DesignRenderPDF
              canvasRender={design.canvasImage}
              configuration={design.config}
              designName={design.config.name}
              projectId={design.config.project}
              data={this.props.data}
              getPdfData={(data)=>{this.getPdfData(index, data)}}
              invisible={true}
              hideWatermark={this.props.hideWatermark}
              includePricing={true}
            />
          }
          <h6>{design.name}</h6>
        </div>
      )
    })
    let readyToSubmit = <div className={styles['status']}>
        <div className={styles['statusHeader']}>
          Progress
        </div>
        <div className={styles['statusContent']}>
          <CircularProgressbar
          value={(this.state.rendered / this.state.ableToSubmit) * 100}
          text={`${this.state.rendered} of ${this.state.ableToSubmit}`}
          styles={progressStyle}
          />
        </div>
      </div>

    if(this.state.proceeding){
      readyToSubmit = <div className={styles['status']}>
        <div className={styles['statusHeader']}>
          Designs Submitted
        </div>
        <div className={styles['statusContent']}>
          <CircularProgressbar
          value={(this.state.designsSubmitted / this.state.selected) * 100}
          text={`${this.state.designsSubmitted} of ${this.state.selected}`}
          styles={progressStyle}
          />
        </div>
      </div>
    }
    let submitButton = <button onClick={this.submitAllReady} disabled={this.state.submitDisabled}>
    Proceed
    </button>
    if(this.state.submitComplete || (this.state.ableToSubmit === 0)){
      submitButton = <button onClick={this.props.onCloseButton}>
      Close
      </button>
    }
    let customMixedMessage = null
    if(this.state.hasCustomMixed){
        customMixedMessage = <div className={styles['alert']}>
          <p><i className='las la-exclamation-triangle'></i>You have a mix of custom designs and standard designs available. Please make sure to select only standard designs or only custom designs <u>before</u> you can proceed.</p>
          <div className={styles['selectArea']}>
            <button className={styles['selectButton']}
            onClick={(e)=>this.selectStandardOrCustom(false)}>
            Select all Standard Designs
            </button>
            <button className={styles['selectButton']}
            onClick={(e)=>this.selectStandardOrCustom(true)}>
            Select all Custom Designs
            </button>
          </div>
        </div>
    }
    return (
      <div>
      <div className={((this.state.submitDisabled || !this.state.submitComplete) ? styles['message'] : styles['messageComplete'])}>{this.state.message}</div>
      <div className={styles['statusBox']}>
        <div className={styles['status']}>
          <div className={styles['statusHeader']}>
            Selected To Submit
          </div>
          <div className={styles['statusContent']}>
            <CircularProgressbar
            value={(this.state.selected / this.state.ableToSubmit) * 100}
            text={`${this.state.selected} of ${this.state.ableToSubmit}`}
            styles={progressStyle}
            />
          </div>
        </div>
        <div className={styles['status']}>
          <div className={styles['statusHeader']}>
            Images Rendered
          </div>
          <div className={styles['statusContent']}>
            <CircularProgressbar
            value={(this.state.renderedImages / this.state.ableToSubmit) * 100}
            text={`${this.state.renderedImages} of ${this.state.ableToSubmit}`}
            styles={progressStyle}
            />
          </div>
        </div>
        <div className={styles['status']}>
          <div className={styles['statusHeader']}>
            PDF Files Rendered
          </div>
          <div className={styles['statusContent']}>
            <CircularProgressbar
            value={(this.state.renderedPdfs / this.state.ableToSubmit) * 100}
            text={`${this.state.renderedPdfs} of ${this.state.ableToSubmit}`}
            styles={progressStyle}
            />
          </div>
        </div>
        {readyToSubmit}
      </div>
      <div className={styles['renderCollection']}>
      {designRenders}
      </div>
      {customMixedMessage}
      <div className={'lightboxActions'}>
        <button
        disabled={this.state.proceeding}
        className={'cancel'}
        onClick={this.props.onCloseButton}>
        Cancel
        </button>
        {submitButton}
      </div>
        <ReactTooltip id="tooltipCustom" backgroundColor="yellow" textColor="#808284">
          <span>This design contains custom surfaces. These designs can only be submitted with other designs that contain custom surfaces.</span>
        </ReactTooltip>
      </div>
    )
  }
}

export default(RenderQueue)

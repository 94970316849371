import React from 'react'
import DesignList from '../DesignList'
import { navigate } from '@reach/router'
import Moment from 'react-moment'
import Lightbox from 'lightbox-react'
import ProjectDuplicate from '../ProjectDuplicate'
import ProjectRenderPDF  from '../../ProjectRenderPDF'
import MultiSubmit from '../MultiSubmit'
import styles from './project-item.module.css'
import WaitLoader from '../../WaitLoader'
import { Form, Field } from 'react-final-form'
import AutoSave from '../../Form/AutoSave'

class ProjectItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeleteOpen: false,
      duplicateOpen: false,
      projectPdfOpen: false,
      submitMultiOpen: false,
      project: null,
      imagesPopulated: false,
      processed: 0
    }
  }

  componentDidMount() {
    this.setState({
      project: this.props.project
    })
  }

  onClickItem = design => {
    navigate('/design/'+design._id)
  }

  save = async values => {
    Object.keys(values).forEach(key => {
       if(key === 'name'){
         this.props.project.name = values[key]
       }else{
         this.props.project[key] = values[key]
       }
    })
    await this.props.onUpdate(this.props.project)
  }

  onConfirmDelete = (e) => {
    e.preventDefault()
    this.setState({
      confirmDeleteOpen: true
    })
  }

  onDelete = () => {
    this.props.deleteProject(this.props.project)
  }

  openProjectDuplicate = () => {
    this.setState({
      duplicateOpen: true
    })
  }

  closeProjectDuplicate = () => {
    this.setState({
      duplicateOpen: false
    })
  }

  onProjectPdfOpen = () => {
    this.setState({
      projectPdfOpen: true
    })
  }

  onSubmitMultiOpen = () => {
    this.setState({
      submitMultiOpen: true
    })
  }

  getCanvasImage = returnImage => {
    if(this.props.project.designs[returnImage.index]){
      this.props.project.designs[returnImage.index].image = returnImage.image
      this.imagesPopulated()
    }
  }

  imagesPopulated = () => {
    let allPopulated = true
    let processed = 0
    this.props.project.designs.forEach(function(design){
      if(!design.image){
        allPopulated = false
      }else{
        processed++
      }
    })
    if(!this.state.imagesPopulated){
      this.setState({
        imagesPopulated: allPopulated,
        processed: processed,
      })
    }
    return allPopulated
  }

  render(){
    let designCount = 0
    if(this.props.project){
      if(this.props.project.designs){
        designCount = this.props.project.designs.length
      }
    }
    let loadingIndicator = ''
    if(this.props.loadingProject){
      loadingIndicator = <div style={{position: 'absolute',
      width: '100%', height: '100%', top: '0px', left: '0px',
      background: 'rgba(255,255,255,0.8)',
      zIndex: '10',
      color: '#ffffff'}}>
      <WaitLoader message="Loading Project Data..." />
      </div>
    }
    let multiSubmitButton = null
    if(this.props.userData?.email.toLowerCase().includes('@forms-surfaces.com')){
      multiSubmitButton = <button title="Submit Multiple Designs"
      onClick={(e) => this.onSubmitMultiOpen(e) }>
      <i className="las la-download"></i>
      Submit Multiple designs
      </button>
    }
    let { confirmDeleteOpen, duplicateOpen, projectPdfOpen } = this.state
    return (
      <div className={styles['page']}>
      <div className={'col-xs-12 ' + styles['projects']}>
        <div className={'row'}>
          <div className={'col-lg-4 col-md-6 col-xs-12 collapse'}>
            <div className={styles['projectThumb']}>
              <div className={styles['designCount']}>
                {designCount}
              </div>
            </div>
            <div className={styles['projectFunctions']}>
              <div className={'row'}>
                <div className={'col-xs-7'}>
                  <div>
                    <button title="Add a new Elevator Interior"
                    onClick={this.props.createDesign}>
                    <i className="las la-plus-circle"></i>  Add Elevator Interior
                    </button>
                  </div>
                  <div>
                    <button title="Duplicate this Project"
                    onClick={this.openProjectDuplicate}>
                    <i className="las la-copy"></i>  Duplicate Project
                    </button>
                  </div>
                </div>
                <div className={'col-xs-5'}>
                  <div>
                    <button title="Delete this Project"
                    onClick={(e) => this.onConfirmDelete(e)}>
                    <i className="las la-trash"></i>  Delete Project
                    </button>
                  </div>
                  <div className={'col-xs-5'}>
                    <div>

                    </div>
                  </div>
                </div>
                <div className={'col-xs-12'}>
                  {multiSubmitButton}
                </div>
              </div>
            </div>
            {loadingIndicator}

          </div>
          <div className={'col-lg-8 col-xs-12'}>
            <div className="row">
              <div className="col-xs-12">
                <p style={{'marginTop': '1em'}}
                  className={styles['projectParagraph']}>
                  Within a project you can create multiple elevator interior
                  designs. You can continue modifying them individually until
                  you request an ‘Advanced Download’. To ensure data accuracy,
                  your design will stay locked until your request is processed.
                  Note that you can only request 'Advanced Download' for
                  individual elevator interiors with a 'Complete' status within
                  a project.
                </p>
                <form>
                  <div className="row">
                    <Form
                      id={this.props.project.id}
                      onSubmit={this.save /* NOT USED, but required */}
                      initialValues={{
                        name: this.props.project.name,
                        company: this.props.project.company,
                        specifier: this.props.project.specifier,
                        jobLocation: this.props.project.jobLocation
                      }}
                      subscription={{} /* No need to subscribe to anything */}
                    >
                      {() => (
                        <div className={'col-sm-6 col-xs-12'}>
                          <AutoSave debounce={1000} save={this.save} />
                          <div className={styles['formField'] + ' ' + styles['required']}>
                            <label htmlFor="projectName" id="projectNameLabel">Project Name:</label>
                            <Field
                              id="projectName"
                              name="name"
                              component="input"
                            >
                            </Field>
                          </div>
                          <div className={styles['formField'] + ' ' + styles['required']}>
                            <label htmlFor="company">Company:</label>
                            <Field
                              id="company"
                              name="company"
                              component="input"
                              >
                            </Field>
                          </div>
                          <div className={styles['formField']}>
                            <label htmlFor="specifier">Specifier:</label>
                            <Field
                              id="specifier"
                              name="specifier"
                              component="input"
                              >
                            </Field>
                          </div>
                          <div className={styles['formField']}>
                            <label htmlFor="jobLocation">Job Location:</label>
                            <Field
                              id="jobLocation"
                              name="jobLocation"
                              component="textarea"
                            />
                          </div>
                        </div>
                      )}
                    </Form>
                    <div className={'col-sm-6 col-xs-'}>
                      <div className={styles['formField']}>
                        <label htmlFor="createdDate">Created:</label>
                        <Moment id="createdDate" format="MM.DD.YYYY hh:mm:ss A">
                          {this.props.project.created || ''}
                        </Moment>
                      </div>
                      <div className={styles['formField']}>
                        <label htmlFor="updatedDate">Last Modified:</label>
                        <Moment id="updatedDate" format="MM.DD.YYYY hh:mm:ss A">
                          {this.props.project.updated || ''}
                        </Moment>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DesignList
          project={this.props.project}
          updateDesign={this.props.updateDesign}
          deleteDesign={this.props.deleteDesign}
          getCanvasImage={this.getCanvasImage}
          data={this.props.data}
          userData={this.props.userData}
          reloadProject={this.props.reloadProject}
        />
      </div>
      {confirmDeleteOpen && (
        <Lightbox
          mainSrc={
          <div className={'lightboxWrapper'}>
            <div className={'deleteConfirm'}>
              <h3>Delete Project: {this.props.project.name}</h3>
              <p>Are you sure you want to delete this project? {this.props.project.designs.length} designs within this project will be destroyed. <span className="red">This action cannot be undone.</span></p>
              <div className={'lightboxActions'}>
                <button onClick={() => this.setState({ confirmDeleteOpen: false})}>Cancel</button>
                <button onClick={this.onDelete}>Confirm</button>
              </div>
            </div>
          </div>
          }
          onCloseRequest={() => this.setState({ confirmDeleteOpen: false })}
          enableZoom={false}
          clickOutsideToClose={true}
        />
      )}
      {
        duplicateOpen && (
          <Lightbox
            mainSrc=<ProjectDuplicate onClose={this.closeProjectDuplicate}
            project={this.props.project}
            />
            onCloseRequest={this.closeProjectDuplicate}
            enableZoom={false}
            clickOutsideToClose={true}
          />
      )}
      {
        projectPdfOpen && (
          <Lightbox
            mainSrc=<ProjectRenderPDF
            project={this.props.project}
            imagesPopulated={this.state.imagesPopulated}
            processed={this.state.processed}
            total={this.props.project.designs.length}
            />
            onCloseRequest={() => this.setState({ projectPdfOpen: false })}
            enableZoom={false}
            clickOutsideToClose={true}
          />
      )}
      {this.state.submitMultiOpen && (
        <Lightbox
        mainSrc=<MultiSubmit
          project={this.props.project}
          data={this.props.data}
          reloadProject={this.props.reloadProject}
          onCloseButton={() => this.setState({ submitMultiOpen: false })}
        />
        onCloseRequest={() => this.setState({ submitMultiOpen: false })}
        enableZoom={false}
        clickOutsideToClose={true}
        />
      )}
      </div>
    )
  }

}

export default ProjectItem

import React from 'react'
import Layout from './Layout'
import ProjectItem from './Project/ProjectItem'
import ErrorMessage from './ErrorMessage'
import { navigate } from '@reach/router'
import {
  getProject,
  updateProject,
  deleteProject
} from '../utils/project'
import {
  createDesign,
  deleteDesign
} from '../utils/design'
import {
  getUserInfo
} from '../utils/user'

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      project: '',
      error: null,
      pageError: null,
      loadingProject: true,
      userData: null
    }
  }

  componentDidMount() {
    getUserInfo()
    .then(function(userData){
      this.setState({
        userData: userData
      }, function(){
        if(this.props.projectid){
          this.getProject(this.props.projectid)
        }
      })
    }.bind(this))
  }

  reloadProject = () => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({
          loadingProject: true
        }, function(){
          this.getProject(this.props.projectid)
          resolve()
        })
      }catch(err){
        reject(err)
      }
    })
  }

  getProject = (projectId) => {
    getProject(projectId)
    .then(function(data){
      this.setState({
        project: data,
        loadingProject: false
      })
    }.bind(this))
    .catch(function(err){
      this.setState({
        pageError: err
      })
    }.bind(this))
  }

  onClickItem = design => {
    navigate('/design/'+design._id)
  }

  saveProject = () => {
    updateProject(this.state.project)
  }

  handleProjectFormUpdate = project => {
    // let project = { ...this.state.project }
    // project[event.target.name] = event.target.value
    this.setState({
      project: project,
    },this.saveProject)
  }

  handleCreateDesign = () => {
    createDesign(this.state.project._id, this.state.project.designs.length + 1)
    .then(function(newDesign){
      navigate('/design/'+newDesign._id)
    })
    .catch(function(err){
      console.log(err)
    })
  }

  handleDeleteProject = (project) => {
    deleteProject(project._id)
    .then(function(data){
      navigate('/profile')
    })
    .catch(function(err){
      console.log(err)
    })
  }

  handleDeleteDesign = (design) => {
    deleteDesign(design._id)
    .then(function(data){
      this.getProject(this.props.projectid)
    }.bind(this))
    .catch(function(err){
      console.log(err)
    })
  }

  render(){
    let error = ''
    let pageError = ''
    if(this.state.pageError){
      pageError = <ErrorMessage
        message={this.state.pageError.message}
        link='/profile'
        linkMessage='Go back to your profile'
        />
    }
    if(this.state.error){
      error = <div className="errorMessage">{this.state.error.message}</div>
    }
    return (
      <Layout wrapperClass="project-page">
      <div className="row">
        {pageError}
        <div className={'col-xs-12 collapse'}>
          <h1 className="projectTitle">My Project | {this.state.project.name || ''}</h1>
        </div>
        {error}
        <ProjectItem
          project={this.state.project}
          loadingProject={this.state.loadingProject}
          onUpdate={this.handleProjectFormUpdate}
          createDesign={this.handleCreateDesign}
          deleteProject={this.handleDeleteProject}
          deleteDesign={this.handleDeleteDesign}
          data={this.props.data}
          userData={this.state.userData}
          reloadProject={this.reloadProject}
          hideWatermark={this.props.hideWatermark}
        />
      </div>
      </Layout>
    )
  }

}

export default Project

import React, { Component } from 'react'
import RenderQueue from './render-queue'
import { navigate } from '@reach/router'
import styles from './multi-submit.module.css'
import {
  constructDesign,
} from '../../../utils/design'

class MultiDesignSubmit extends Component {
  constructor(props) {
    super(props)

    if(this.props.project){
      let readyDesigns = this.props.project.designs.filter(function(design, i){
        let configuration = constructDesign(design, this.props.data)
        design.config = configuration
        design.selected = true
        let additionalDetails = (configuration?.additionalDetails?.ceilingHeight && configuration?.additionalDetails?.shellHeight && configuration?.additionalDetails?.width && configuration?.additionalDetails?.depth)
        return (configuration.completed && design.status !== 'Submitted' && !design.locked && !configuration.hidelsbwrs && additionalDetails)
      }.bind(this))
      this.props.project.designs = readyDesigns
    }

    this.state = {
      project: this.props.project,
      submitDisabled: true,
      proceeding: false
    }
  }

  componentDidMount() {

  }


  setErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
      proceeding: false
    })
  }

  detailsComplete = (design) => {

  }

  onProceed = () => {

  }

  onCloseButton = () => {
    if(this.props.reloadProject){
      this.props.reloadProject()
      .then(function(){
        this.props.onCloseButton()
      }.bind(this))
    }else{
      navigate('/project/'+this.props.project._id)
    }
  }

  render() {
    let processingMessage = ''

    return (
      <div className={'lightboxWrapper'}>
        <div className={'deleteConfirm ' + styles['design__submit']}>
          <RenderQueue project={this.state.project} data={this.props.data} onCloseButton={this.onCloseButton} hideWatermark={this.props.hideWatermark} />
          {processingMessage}
          {this.state.errorMessage}
        </div>
      </div>
    )
  }
}

export default(MultiDesignSubmit)
